import React from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

const HeroV2 = props => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  return (
    <div data-testid="herov2" className="rx-herov2">
      <section
        className="rx-c-hero"
        style={{
          ...(props.bgColor
            ? {
                backgroundColor: `${props.bgColor.value}`
              }
            : null)
        }}
      >
        <div className="rx-c-hero--background-image">
          <picture
            className="placeholder"
            style={{
              ...(props._rawHeroBgImage.asset.metadata
                ? {
                    paddingTop: `calc(50% / ${props._rawHeroBgImage.asset.metadata.dimensions.aspectRatio})`,
                    paddingBottom: `calc(27% / ${props._rawHeroBgImage.asset.metadata.dimensions.aspectRatio})`,
                    background: `url(${props._rawHeroBgImage.asset.metadata.lqip})`,
                    backgroundSize: "cover"
                  }
                : null)
            }}
          >
            <source
              media={"(min-width: 1200px)"}
              srcSet={
                urlBuilder
                  .image(props._rawHeroBgImage)
                  .auto("format")
                  .quality(80)
                  .width(1400)
                  .height(520)
                  .url() as string
              }
            />
            <source
              media={"(min-width: 992px)"}
              srcSet={
                urlBuilder
                  .image(props._rawHeroBgImage)
                  .auto("format")
                  .quality(80)
                  .width(900)
                  .height(440)
                  .url() as string
              }
            />
            <source
              media={"(min-width: 641px)"}
              srcSet={
                urlBuilder
                  .image(props._rawHeroBgImage)
                  .auto("format")
                  .quality(80)
                  .width(600)
                  .height(440)
                  .url() as string
              }
            />
            <img
              src={
                urlBuilder
                  .image(props._rawHeroBgImage)
                  .auto("format")
                  .quality(80)
                  .width(540)
                  .height(400)
                  .url() as string
              }
              alt={props._rawHeroBgImage?.alt}
            />
          </picture>
        </div>
        <Container fluid>
          <Row>
            <Col sm="5">
              <picture className="logo">
                <source
                  media={"(min-width: 641px)"}
                  srcSet={urlBuilder.image(props._rawHeroImage).auto("format").quality(80).fit("crop").url() as string}
                />
                <img
                  src={urlBuilder.image(props._rawHeroImage).auto("format").quality(80).fit("crop").url() as string}
                  alt={props._rawHeroImage.alt}
                  className="img-fluid"
                />
              </picture>
              <div className="rx-c-hero-copy">
                <BlockContent blocks={props._rawCopyText} serializers={RichTextSerializers()} />
              </div>
            </Col>
            <Col sm="7">
              <picture className="rx-bg">
                <img
                  src={
                    urlBuilder
                      .image(props._rawHeroBgImage)
                      .auto("format")
                      .quality(80)
                      .fit("crop")
                      .width(991)
                      .height(991)
                      .url() || undefined
                  }
                  alt={props._rawHeroBgImage?.alt}
                  loading={"lazy"}
                  className="img-fluid"
                />
              </picture>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HeroV2;
